import React from 'react';
import { connect } from 'react-redux'
import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import configureStore from "store";

export const store = configureStore();

class UserInfoPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            roleText: ''
        };
    };

    componentDidMount() {
        const user = store.getState().auth.authUser;
        if (user !== null) {
            this.setState({
                name: user.name,
                roleText: user.role_text
            });
        }
    }

    handleClickProfile = () => {
        window.location.href = '/app/profiles';
    }

    handleClickChangePassword = () => {
        window.location.href = '/app/change-passwords';
    }

    render() {
        return (
            <div>
                <div className="user-profile">
                    <div className="user-detail ml-2">
                        <h4 className="user-name mb-0">{this.state.name}</h4>
                        <small>{this.state.role_text}</small>
                    </div>
                </div>
                <span className="jr-link dropdown-item text-muted" onClick={this.handleClickProfile}>
                    <i className="zmdi zmdi-face zmdi-hc-fw mr-1" />
                    <IntlMessages id="popup.profile" />
                </span>
                <span className="jr-link dropdown-item text-muted" onClick={this.handleClickChangePassword}>
                    <i className="zmdi zmdi-lock zmdi-hc-fw mr-1" />
                    Ubah Password
                </span>
                <span className="jr-link dropdown-item text-muted" onClick={() => {
                    this.props.userSignOut()
                }}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
                    Keluar
                </span>
            </div>
        );
    }
}

export default connect(null, { userSignOut })(UserInfoPopup);


