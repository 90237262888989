import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { userSignIn } from 'actions/Auth';
import { MainLogo, AppName } from 'constants/Environtments';
import { FormHelperText } from '@material-ui/core';
import { forgotPassword } from 'util/ApiFetchers';
import { UncontrolledAlert } from 'reactstrap';
import { API_UNPROCESSABLE_ENTITY, API_INTERNAL_SERVER_ERROR } from 'constants/ErrorCode';

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailErr: null,
            emailSuccess: null,
        };
        this.handleClickResetPassword = this.handleClickResetPassword.bind(this);
    }
    componentDidUpdate() {
        if (this.props.authUser !== null) {
            this.props.history.push('/');
        }
    }
    handleClickResetPassword = () => {
        let request = new FormData();
        request.append('email', this.state.email);

        let errors = [];
        for (var pair of request.entries()) {
            if (pair[1] === '') {
                this.validation(pair[0]);
                errors.push(pair[0]);
            }
        }

        if (errors.length === 0) {
            forgotPassword(request).then(({ data }) => {
                if (data.success) {
                    this.setState({ emailSuccess: data.message });
                }
            }).catch(error => {
                if (error.response.data) {                    
                    switch (error.response.data.code) {
                        case API_INTERNAL_SERVER_ERROR:
                            this.setState({
                                emailErr: error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
                            });
                            break;
                        case API_UNPROCESSABLE_ENTITY:
                            this.setState({
                                [Object.keys(error.response.data.errors)[0] + 'Err']: error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
                            });
                            break;
    
                        default:
                            break;
                    }
                }
            });
        }
    }
    validation = (field, message = null) => {
        if (field === 'email') {
            this.setState({
                emailErr: message ? message : 'Field email is required.'
            });
        }
    }

    render() {
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title={AppName}>
                            <img src={MainLogo} height="110" alt={AppName} title={AppName} />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1>Reset Password {AppName}</h1>
                        </div>

                        <div className="app-login-form">
                            {this.state.emailSuccess ?
                                <UncontrolledAlert className="bg-success text-white shadow-lg">
                                    {this.state.emailSuccess}
                                </UncontrolledAlert> : ''
                            }
                            {this.state.emailErr ?
                                <UncontrolledAlert className="bg-danger text-white shadow-lg">
                                    {this.state.emailErr}
                                </UncontrolledAlert> : ''
                            }
                            <ErrorBoundary>

                                <form>
                                    <fieldset>
                                        <TextField
                                            label={<IntlMessages id="appModule.email" />}
                                            fullWidth
                                            onChange={(event) => this.setState({ email: event.target.value, emailErr: null })}
                                            defaultValue={this.state.email}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                            error={this.state.emailErr}
                                        />
                                        {this.state.emailErr ?
                                            <FormHelperText className="text-danger mb-3">{this.state.emailErr}</FormHelperText> : ''}

                                        <div className="mb-3 d-flex align-items-center justify-content-between">
                                            <Button onClick={this.handleClickResetPassword} variant="contained" color="primary">
                                                Kirim Email Reset Password
                                            </Button>

                                            <Link to="/signin">
                                                Masuk
                                            </Link>
                                        </div>

                                    </fieldset>
                                </form>
                            </ErrorBoundary>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser }
};

export default connect(mapStateToProps, { userSignIn })(SignIn);
