import axios from 'util/Api';

export function userRegister(request) {
    const url = '/v1/registers';
    const headers = {
        'Access-Control-Allow-Origin': '*'
    };
    request.append('_method', 'POST');
    return axios.post(url, request, headers);
}

export function verifyEmail(request) {
    const url = '/v1/verifys';
    return axios.post(url, request);
}

export function requestToken(request) {
    const url = '/v1/tokens';
    return axios.post(url, request);
}

export function forgotPassword(request) {
    const url = '/v1/forgot-passwords';
    return axios.post(url, request);
}

export function resetPassword(request) {
    const url = '/v1/password-resets';
    return axios.post(url, request);
}

export function getProfile() {
    const url = '/v1/profiles';
    return axios.get(url);
}

export function updateMyProfile(request) {
    const url = '/v1/profiles';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function changeMyPassword(request) {
    const url = '/v1/change-passwords';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function signOut() {
    const url = '/v1/logout';
    return axios.post(url);
}

export function getUserList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/users?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function getUserById(userId) {
    const url = '/v1/users/' + userId;
    return axios.get(url);
}

export function createNewUser(request) {
    const url = '/v1/users';
    const headers = {
        "Content-Type": "multipart/form-data"
    }

    request.append('_method', 'POST');
    return axios.post(url, request, headers);
}

export function updateUserById(userId, request) {
    const url = '/v1/users/' + userId;
    const headers = {
        "Content-Type": "multipart/form-data"
    }

    request.append('_method', 'PATCH');
    return axios.post(url, request, headers);
}

export function deleteUserById(userId) {
    const url = '/v1/users/' + userId;
    return axios.post(url, {
        _method: 'DELETE'
    });
}

export function getUserRole() {
    const url = '/v1/user-roles';
    return axios.get(url);
}

export function getUserStatus() {
    const url = '/v1/user-statuses';
    return axios.get(url);
}

export function getTopicList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/topics?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function deleteTopicById(topicId) {
    const url = '/v1/topics/' + topicId;
    return axios.post(url, {
        _method: 'DELETE'
    });
}

export function createNewTopic(request) {
    const url = '/v1/topics';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function getTopicById(topicId) {
    const url = '/v1/topics/' + topicId;
    return axios.get(url);
}

export function updateTopicById(userId, request) {
    const url = '/v1/topics/' + userId;
    request.append('_method', 'PATCH');
    return axios.post(url, request);
}

export function getAbstractList(perPage = 5, currentPage = 0, keyword = '', queryString = {}) {
    const url = '/v1/abstracts?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword + '&status=' + queryString.status + '&topic=' + queryString.topic;
    return axios.get(url);
}

export function getAbstractById(abstractId) {
    const url = '/v1/abstracts/' + abstractId;
    return axios.get(url);
}

export function updateAbstractById(abstractId, request) {
    const url = '/v1/abstracts/' + abstractId;
    request.append('_method', 'PATCH');
    return axios.post(url, request);
}

export function deleteAbstractById(abstractId) {
    const url = '/v1/abstracts/' + abstractId;
    return axios.post(url, {
        _method: 'DELETE'
    });
}

export function createNewAbstract(request) {
    const url = '/v1/abstracts';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function getCategoryList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/categories?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function getPreferenceList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/preferences?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function createAbstractAuthor(abstractId, request) {
    const url = '/v1/abstracts/' + abstractId + '/authors';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function deleteAbstractAuthor(abstractId, abstractAuthorId) {
    const url = '/v1/abstracts/' + abstractId + '/authors/' + abstractAuthorId;
    return axios.post(url);
}

export function getAuthorRoleList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/author-roles?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function getQuestionnaireList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/questionnaires?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function createNewQuestionnaire(request) {
    const url = '/v1/questionnaires';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function getQuestionnaireById(questionnaireId) {
    const url = '/v1/questionnaires/' + questionnaireId;
    return axios.get(url);
}

export function updateQuestionnaireById(questionnaireId, request) {
    const url = '/v1/questionnaires/' + questionnaireId;
    request.append('_method', 'PATCH');
    return axios.post(url, request);
}

export function deleteQuestionnaireById(questionnaireId) {
    const url = '/v1/questionnaires/' + questionnaireId;
    return axios.post(url, {
        _method: 'DELETE'
    });
}

export function getPriorityList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/priorities?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function getSubmissionStatusList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/submission-statuses?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function getQuestionnaireTypeList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/questionnaire-types?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function createNewReview(abstractId, request) {
    const url = '/v1/abstracts/' + abstractId + '/reviews';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function getReviewByAbstractId(abstractId, perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/abstracts/' + abstractId + '/reviews?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function getReviewSummaryByAbstractId(abstractId, perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/abstracts/' + abstractId + '/review-summaries?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function getAbstractConfirmation(abstractId) {
    const url = '/v1/abstracts/' + abstractId + '/confirmations';
    return axios.get(url);
}

export function storeAbstractConfirmation(abstractId, request) {
    const url = '/v1/abstracts/' + abstractId + '/confirmations';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function getChapterByAbstractId(abstractId) {
    const url = '/v1/abstracts/' + abstractId + '/chapters';
    return axios.get(url);
}

export function createChapter(abstractId, request) {
    const url = '/v1/abstracts/' + abstractId + '/chapters';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function updateChapter(abstractId, chapterId, request) {
    const url = '/v1/abstracts/' + abstractId + '/chapters/' + chapterId;
    request.append('_method', 'PATCH');
    return axios.post(url, request);
}

export function deleteChapter(abstractId, chapterId) {
    const url = '/v1/abstracts/' + abstractId + '/chapters/' + chapterId;
    return axios.post(url, {_method: 'DELETE'});
}

export function getTableByAbstractId(abstractId) {
    const url = '/v1/abstracts/' + abstractId + '/tables';
    return axios.get(url);
}

export function createTable(abstractId, request) {
    const url = '/v1/abstracts/' + abstractId + '/tables';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function updateTable(abstractId, tableId, request) {
    const url = '/v1/abstracts/' + abstractId + '/tables/' + tableId;
    request.append('_method', 'PATCH');
    return axios.post(url, request);
}

export function deleteTable(abstractId, tableId) {
    const url = '/v1/abstracts/' + abstractId + '/tables/' + tableId;
    return axios.post(url, {_method: 'DELETE'});
}

export function getGalleryByAbstractId(abstractId) {
    const url = '/v1/abstracts/' + abstractId + '/galleries';
    return axios.get(url);
}

export function createGallery(abstractId, request) {
    const url = '/v1/abstracts/' + abstractId + '/galleries';
    const headers = {"Content-Type": "multipart/form-data"};
    request.append('_method', 'POST');
    return axios.post(url, request, headers);
}

export function deleteGallery(abstractId, galleryId) {
    const url = '/v1/abstracts/' + abstractId + '/galleries/' + galleryId;
    return axios.post(url, {_method: 'DELETE'});
}

export function setLockedAbstract(abstractId, request) {
    const url = '/v1/abstracts/' + abstractId + '/locks';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function getBankAccountList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/banks?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function getBankAccountById(bankAccountId) {
    const url = '/v1/banks/' + bankAccountId;
    return axios.get(url);
}

export function createBankAccount(request) {
    const url = '/v1/banks';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function updateBankAccountById(bankAccountId, request) {
    const url = '/v1/banks/' + bankAccountId;
    request.append('_method', 'PATCH');
    return axios.post(url, request);
}

export function deleteBankAccountById(bankAccountId) {
    const url = '/v1/banks/' + bankAccountId;
    return axios.post(url, {_method: 'DELETE'});
}

export function updatePayment(abstractId, request) {
    const url = '/v1/abstracts/' + abstractId + '/payments';
    const headers = {"Content-Type": "multipart/form-data"};
    request.append('_method', 'POST');
    return axios.post(url, request, headers);
}

export function getPayment(abstractId) {
    const url = '/v1/abstracts/' + abstractId + '/payments';
    return axios.get(url);
}

export function getPeriodList() {
    const url = '/v1/periods';
    return axios.get(url);
}

export function updatePeriod(request) {
    const url = '/v1/periods';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function updatePeriodById(periodId, request) {
    const url = '/v1/periods/' + periodId;
    request.append('_method', 'PATCH');
    return axios.post(url, request);
}

export function getEmailTemplateList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/email-templates?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function getEmailTemplateById(templateId) {
    const url = '/v1/email-templates/' + templateId;
    return axios.get(url);
}

export function createEmailTemplate(request) {
    const url = '/v1/email-templates';
    const headers = {"Content-Type": "multipart/form-data"};
    request.append('_method', 'POST');
    return axios.post(url, request, headers);
}

export function updateEmailTemplateById(templateId, request) {
    const url = '/v1/email-templates/' + templateId;
    const headers = {"Content-Type": "multipart/form-data"};
    request.append('_method', 'PATCH');
    return axios.post(url, request, headers);
}

export function deleteEmailTemplateById(templateId) {
    const url = '/v1/email-templates/' + templateId;
    return axios.post(url, {_method: 'DELETE'});
}

export function deleteEmailTemplateAttachmentById(templateId, attachmentId) {
    const url = '/v1/email-templates/' + templateId;
    return axios.post(url, {_method: 'DELETE', attachment_id: attachmentId});
}

export function getEmailTargetList() {
    const url = '/v1/targets';
    return axios.get(url);
}

export function doBlastEmail(request) {
    const url = '/v1/email-blasts';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function getEmailBlastLog(templateId = '') {
    const url = '/v1/email-blasts/logs?&template_id=' + templateId;
    return axios.get(url);
}

export function requestDownloadProceeding(request) {
    const url = '/v1/proceedings';
    request.append('_method', 'POST');
    return axios.post(url, request);
}

export function getProceedingList(perPage = 5, currentPage = 0, keyword = '') {
    const url = '/v1/proceedings?&perPage=' + perPage + '&page=' + currentPage + '&keyword=' + keyword;
    return axios.get(url);
}

export function deleteProceedingById(proceedingId) {
    const url = '/v1/proceedings/' + proceedingId;
    return axios.post(url, {_method: 'DELETE'});
}

export function getUserAndSubmissionStatistic() {
    const url = '/v1/reports/user-submission-statistics';
    return axios.get(url);
}

export function getTopTopic(limit = 10) {
    const url = '/v1/reports/top-topics?&limit=' + limit;
    return axios.get(url);
}

export function getTopKeyword(limit = 10) {
    const url = '/v1/reports/top-keywords?&limit=' + limit;
    return axios.get(url);
}

export function getTodayEvent() {
    const url = '/v1/reports/today-events';
    return axios.get(url);
}

export function getSubmissionSummary() {
    const url = '/v1/reports/submission-summaries';
    return axios.get(url);
}
