import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import configureStore from "store";
import { IN_REVIEW, APPROVED, CANCELED, REJECTED } from 'constants/SubmissionStatus';

const store = configureStore();

class Menu extends Component {
    componentDidMount() {
        const { history } = this.props;

        const pathname = `#${history.location.pathname}`;// get current path
        const mainMenu = document.getElementsByClassName('nav-item');
        for (let i = 0; i < mainMenu.length; i++) {
            mainMenu[i].onclick = function () {
                for (let j = 0; j < mainMenu.length; j++) {
                    if (mainMenu[j].classList.contains('active')) {
                        mainMenu[j].classList.remove('active')
                    }
                }
                this.classList.toggle('active');
            }
        }
        const subMenuLi = document.getElementsByClassName('nav-arrow');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function () {
                for (let j = 0; j < subMenuLi.length; j++) {
                    if (subMenuLi[j].classList.contains('active')) {
                        subMenuLi[j].classList.remove('active')
                    }
                }
                this.classList.toggle('active');
            }
        }
        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('active');
            } else {
                this.closest(activeLi, 'li').classList.add('active');
            }
            const parentNav = this.closest(activeNav, '.nav-item');
            if (parentNav) {
                parentNav.classList.add('active');
            }

        } catch (e) {

        }

    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }


    render() {
        const visibleMenu = store.getState().auth.visibleMenu;

        return (
            <div className="app-main-menu d-none d-md-block">
                <ul className="navbar-nav navbar-nav-mega">

                    {visibleMenu.reference ?
                        <li className="nav-item hide">
                            <span className="nav-link">Referensi</span>
                            <ul className="sub-menu">
                                {visibleMenu.topicList ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/topics">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Topik
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.questionnaireList ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/questionnaires">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Kuesioner
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.bankAccount ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/banks">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Rekening Bank
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.periodMenu ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/periods">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Pengaturan Periode
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.emailTemplate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/email-templates">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Template Email
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }

                    {visibleMenu.emailTemplate ?
                        <li className="nav-item hide">
                            <span className="nav-link">Email Massal</span>
                            <ul className="sub-menu">
                                {visibleMenu.emailTemplate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/email-templates">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Template Email
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.emailTemplate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/email-templates/logs">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Riwayat
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }


                    {visibleMenu.user ?
                        <li className="nav-item">
                            <span className="nav-link">Pengguna</span>
                            <ul className="sub-menu">
                                {visibleMenu.userCreate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/users/create">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    <i className="zmdi zmdi-plus zmdi-hc-fw" />
                                                    Tambah Pengguna Baru
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''
                                }
                                {visibleMenu.userList ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/users">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                                                    Daftar Pengguna
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }

                    {visibleMenu.submission ?
                        <li className="nav-item">
                            <span className="nav-link">Submisi</span>
                            <ul className="sub-menu">
                                {visibleMenu.abstractCreate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/abstracts/create">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Ajukan Submisi
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status="}>
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Daftar Semua Submisi
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status=" + IN_REVIEW}>
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Dalam Peninjauan
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status=" + APPROVED}>
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Disetujui
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status=" + CANCELED}>
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Dibatalkan
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {visibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status=" + REJECTED}>
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Ditolak
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''}

                    {visibleMenu.fullpaper ?
                        <li className="nav-item hide">
                            <span className="nav-link">FullPaper</span>
                            <ul className="sub-menu">
                                {visibleMenu.confirmedSubmission ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/abstracts/confirmed">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Submisi Terkonfirmasi
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }

                    {visibleMenu.proceedingView ?
                        <li className="nav-item hide">
                            <span className="nav-link">Proceeding</span>
                            <ul className="sub-menu">
                                {visibleMenu.proceedingView ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/proceedings">
                                            <span className="nav-link">
                                                <span className="nav-text">
                                                    Unduh Proceeding
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }
                </ul>
            </div>
        );
    }
}

export default withRouter(Menu);