import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { MainLogo, AppName } from 'constants/Environtments';
import { FormHelperText } from '@material-ui/core';
import queryString from 'query-string'
import { resetPassword } from 'util/ApiFetchers';
import { UncontrolledAlert } from 'reactstrap';
import { API_UNPROCESSABLE_ENTITY, API_INTERNAL_SERVER_ERROR } from 'constants/ErrorCode';

class PasswordResets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValid: true,
            email: '',
            emailErr: null,
            password: '',
            passwordErr: null,
            passwordConfirmation: '',
            passwordConfirmationErr: null,

            isShowMessage: false,
            messageText: '',
            messageState: '',
        };
    }
    componentWillMount(){
        if (queryString.parse(this.props.location.search).token === undefined) {
            this.props.history.push('/signin');
        }
    }
    handleClickReset = () => {
        this.validation(['email', 'password', 'passwordConfirmation']);
        if (this.state.formValid) {
            let request = new FormData();
            request.append('token', queryString.parse(this.props.location.search).token);
            request.append('email', this.state.email);
            request.append('password', this.state.password);
            request.append('password_confirmation', this.state.passwordConfirmation);
            resetPassword(request).then(({data}) => {
                if (data.success) {
                    this.setState({
                        isShowMessage: true,
                        messageText: 'Kata sandi berhasil diatur ulang, silakan login.',
                        messageState: 'success'
                    });
                    this.props.history.push('/signin');
                }
            }).catch(error => {
                if (error.response.data) {                    
                    switch (error.response.data.code) {
                        case API_INTERNAL_SERVER_ERROR:
                            this.setState({
                                isShowMessage: true,
                                messageText: error.response.data.errors[Object.keys(error.response.data.errors)[0]][0],
                                messageState: 'danger' 
                            });
                            break;
                        case API_UNPROCESSABLE_ENTITY:
                            if (Object.keys(error.response.data.errors)[0] === 'token') {
                                this.setState({
                                    isShowMessage: true,
                                    messageText: 'Token tidak valid!',
                                    messageState: 'danger' 
                                });
                            } else {
                                this.setState({
                                    [Object.keys(error.response.data.errors)[0] + 'Err']: error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
                                });
                            }
                            break;
    
                        default:
                            break;
                    }
                }
            })
        }
    }
    validation = (fields) => {
        if (Array.isArray(fields) && fields.length > 0) {
            fields.forEach(field => {
                switch (field) {
                    case 'email':
                        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if (!emailRegex.test(String(this.state.email).toLowerCase())) {
                            this.setState({ formValid: false, emailErr: 'Alamat email tidak valid.' });
                        }
                        break;
                    case 'password':
                        if (this.state.password.length < 6) {
                            this.setState({ formValid: false, passwordErr: 'Kata sandi minimal harus 6 karakter' });
                        }
                        break;
                    case 'passwordConfirmation':
                        if (this.state.passwordConfirmation !== this.state.password) {
                            this.setState({ formValid: false, passwordConfirmationErr: 'Konfirmasi kata sandi tidak cocok.' });
                        }
                        break;

                    default:
                        break;
                }
            });
        }
    }

    render() {
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title={AppName}>
                            <img src={MainLogo} height="110" alt={AppName} title={AppName} />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1>Atur Ulang Kata Sandi {AppName}</h1>
                        </div>

                        <div className="app-login-form">
                            {this.state.isShowMessage ?
                                <UncontrolledAlert className={`bg-${this.state.messageState} text-white shadow-lg`}>
                                    {this.state.messageText}
                                </UncontrolledAlert> : ''
                            }
                            <ErrorBoundary>

                                <form>
                                    <fieldset>
                                        <TextField
                                            label="Email"
                                            fullWidth
                                            onChange={(event) => this.setState({ email: event.target.value, emailErr: null })}
                                            defaultValue={this.state.email}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                            error={this.state.emailErr}
                                        />
                                        {this.state.emailErr ?
                                            <FormHelperText className="text-danger mb-3">{this.state.emailErr}</FormHelperText> : ''}

                                        <TextField
                                            label="Kata Sandi Baru"
                                            type="password"
                                            fullWidth
                                            onChange={(event) => this.setState({ password: event.target.value, passwordErr: null })}
                                            defaultValue={this.state.password}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                            error={this.state.passwordErr}
                                        />
                                        {this.state.passwordErr ?
                                            <FormHelperText className="text-danger mb-3">{this.state.passwordErr}</FormHelperText> : ''}

                                        <TextField
                                            label="Konfirmasi Kata Sandi Baru"
                                            type="password"
                                            fullWidth
                                            onChange={(event) => this.setState({ passwordConfirmation: event.target.value, passwordConfirmationErr: null })}
                                            defaultValue={this.state.passwordConfirmation}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                            error={this.state.passwordConfirmationErr}
                                        />
                                        {this.state.passwordConfirmationErr ?
                                            <FormHelperText className="text-danger mb-3">{this.state.passwordConfirmationErr}</FormHelperText> : ''}

                                        <div className="mb-3 d-flex align-items-center justify-content-between">
                                            <Button onClick={this.handleClickReset} variant="contained" color="primary">
                                                Atur Ulang
                                            </Button>

                                            <Link to="/signin">
                                                Masuk
                                            </Link>
                                        </div>

                                    </fieldset>
                                </form>
                            </ErrorBoundary>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default PasswordResets;
