import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import { userSignIn } from 'actions/Auth';
import { MainLogo, AppName } from 'constants/Environtments';
import { FormHelperText } from '@material-ui/core';
import { requestToken } from 'util/ApiFetchers';

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailErr: null,
            password: '',
            passwordErr: null
        }
    }

    componentDidUpdate() {
        if (this.props.authUser !== null) {
            this.props.history.push('/');
        }
    }

    handleSignIn = () => {
        let request = new FormData();
        request.append('email', this.state.email);
        request.append('password', this.state.password);

        let errors = [];
        for (var pair of request.entries()) {
            if (pair[1] === '') {
                this.validation(pair[0]);
                errors.push(pair[0]);
            }
        }

        if (errors.length === 0) {
            requestToken(request).then(({ data }) => {
                this.props.userSignIn(data.data);
            }).catch(error => {
                if (error.response.data.code === '0391') {
                    Object.keys(error.response.data.errors).map(field =>
                        this.validation(field, error.response.data.errors[field])
                    );
                } else {
                    if (error.response.data.errors[0] === 'Incorrect Password!') {
                        this.validation('password', 'Incorrect Password!');
                    }
                }
            });
        }
    }

    validation = (field, message = null) => {
        if (field === 'password') {
            this.setState({
                passwordErr: message ? message: 'Password is required'
            });
        }

        if (field === 'email') {
            this.setState({
                emailErr: message ? message: 'Field email is required.'
            });
        }
    }

    render() {
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title={AppName}>
                            <img src={MainLogo} height="110" alt={AppName} title={AppName} />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1>Masuk ke {AppName}</h1>
                        </div>

                        <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <TextField
                                        label={<IntlMessages id="appModule.email" />}
                                        fullWidth
                                        onChange={(event) => this.setState({ email: event.target.value, emailErr: null })}
                                        defaultValue={this.state.email}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                        error={this.state.emailErr}
                                    />
                                    {this.state.emailErr ?
                                        <FormHelperText className="text-danger mb-3">{this.state.emailErr}</FormHelperText> : ''}

                                    <TextField
                                        type="password"
                                        label={<IntlMessages id="appModule.password" />}
                                        fullWidth
                                        onChange={(event) => this.setState({ password: event.target.value, passwordErr: null })}
                                        defaultValue={this.state.password}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                        error={this.state.passwordErr}
                                    />
                                    {this.state.passwordErr ?
                                        <FormHelperText className="text-danger mb-3">{this.state.passwordErr}</FormHelperText> : ''}

                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button onClick={this.handleSignIn} variant="contained" color="primary">
                                            Masuk
                                        </Button>

                                        <Link to="/forgot-passwords">
                                            Lupa Password ?
                                        </Link>
                                        <Link to="/signup">
                                            Buat Akun Baru
                                        </Link>
                                    </div>

                                </fieldset>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser }
};

export default connect(mapStateToProps, { userSignIn })(SignIn);
