import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import Tour from '../components/Tour/index';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import { NotificationContainer } from 'react-notifications';

class App extends React.Component {

    render() {
        const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        }
        else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }

        return (
            <div className={`app-container ${drawerStyle}`}>
                <Tour />

                <Sidebar />
                <div className="app-main-container">
                    <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                            <TopNav styleName="app-top-header" />}
                        <Header />
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                            <TopNav />}
                    </div>

                    <main className="app-main-content-wrapper">
                        <div className="app-main-content">
                            <Switch>
                                <Route path={`${match.url}/dashboard`}
                                    component={asyncComponent(() => import('./routes/Dashboard'))} />
                                <Route path={`${match.url}/users/create`}
                                    component={asyncComponent(() => import('./routes/Users/Create'))} />
                                <Route path={`${match.url}/users/:userId/edit`}
                                    component={asyncComponent(() => import('./routes/Users/Edit'))} />
                                <Route path={`${match.url}/users/:userId`}
                                    component={asyncComponent(() => import('./routes/Users/Show'))} />
                                <Route path={`${match.url}/users`}
                                    component={asyncComponent(() => import('./routes/Users/Index'))} />

                                <Route path={`${match.url}/topics/create`}
                                    component={asyncComponent(() => import('./routes/Topics/Create'))} />
                                <Route path={`${match.url}/topics/:topicId/edit`}
                                    component={asyncComponent(() => import('./routes/Topics/Edit'))} />
                                <Route path={`${match.url}/topics/:topicId`}
                                    component={asyncComponent(() => import('./routes/Topics/Show'))} />
                                <Route path={`${match.url}/topics`}
                                    component={asyncComponent(() => import('./routes/Topics/Index'))} />

                                <Route path={`${match.url}/banks/create`}
                                    component={asyncComponent(() => import('./routes/BankAccounts/Create'))} />
                                <Route path={`${match.url}/banks/:bankAccountId/edit`}
                                    component={asyncComponent(() => import('./routes/BankAccounts/Edit'))} />
                                <Route path={`${match.url}/banks`}
                                    component={asyncComponent(() => import('./routes/BankAccounts/Index'))} />

                                <Route path={`${match.url}/periods`}
                                    component={asyncComponent(() => import('./routes/Periods/Show'))} />

                                <Route path={`${match.url}/abstracts/create`}
                                    component={asyncComponent(() => import('./routes/Abstracts/Create'))} />
                                <Route path={`${match.url}/abstracts/confirmed/:abstractId/payments`}
                                    component={asyncComponent(() => import('./routes/FullPaper/Payment'))} />
                                <Route path={`${match.url}/abstracts/confirmed/:abstractId`}
                                    component={asyncComponent(() => import('./routes/FullPaper/Show'))} />
                                <Route path={`${match.url}/abstracts/confirmed`}
                                    component={asyncComponent(() => import('./routes/FullPaper/Index'))} />
                                <Route path={`${match.url}/abstracts/:abstractId/reviews/create`}
                                    component={asyncComponent(() => import('./routes/Reviews/CreateReview'))} />
                                <Route path={`${match.url}/abstracts/:abstractId/reviews`}
                                    component={asyncComponent(() => import('./routes/Reviews/Index'))} />
                                <Route path={`${match.url}/abstracts/:abstractId/confirmations`}
                                    component={asyncComponent(() => import('./routes/Confirmations/Index'))} />
                                <Route path={`${match.url}/abstracts/:abstractId`}
                                    component={asyncComponent(() => import('./routes/Abstracts/Show'))} />
                                <Route path={`${match.url}/abstracts`}
                                    component={asyncComponent(() => import('./routes/Abstracts/Index'))} />

                                <Route path={`${match.url}/questionnaires/create`}
                                    component={asyncComponent(() => import('./routes/Questionnaires/Create'))} />
                                <Route path={`${match.url}/questionnaires/:questionnaireId/edit`}
                                    component={asyncComponent(() => import('./routes/Questionnaires/Edit'))} />
                                <Route path={`${match.url}/questionnaires/:questionnaireId`}
                                    component={asyncComponent(() => import('./routes/Questionnaires/Show'))} />
                                <Route path={`${match.url}/questionnaires`}
                                    component={asyncComponent(() => import('./routes/Questionnaires/Index'))} />

                                <Route path={`${match.url}/email-templates/create`}
                                    component={asyncComponent(() => import('./routes/EmailTemplates/Create'))} />
                                <Route path={`${match.url}/email-templates/logs`}
                                    component={asyncComponent(() => import('./routes/EmailTemplateLogs/Index'))} />
                                <Route path={`${match.url}/email-templates/:templateId/edit`}
                                    component={asyncComponent(() => import('./routes/EmailTemplates/Edit'))} />
                                <Route path={`${match.url}/email-templates/:templateId`}
                                    component={asyncComponent(() => import('./routes/EmailTemplates/Show'))} />
                                <Route path={`${match.url}/email-templates`}
                                    component={asyncComponent(() => import('./routes/EmailTemplates/Index'))} />

                                <Route path={`${match.url}/proceedings`}
                                    component={asyncComponent(() => import('./routes/Proceedings/Index'))} />

                                <Route path={`${match.url}/profiles`}
                                    component={asyncComponent(() => import('./routes/Auths/Profile'))} />
                                <Route path={`${match.url}/change-passwords`}
                                    component={asyncComponent(() => import('./routes/Auths/ChangePassword'))} />

                                <Route component={asyncComponent(() => import('components/Error404'))} />
                            </Switch>
                        </div>
                        <NotificationContainer />
                        <Footer />
                    </main>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({ settings }) => {
    const { drawerType, navigationStyle, horizontalNavPosition } = settings;
    return { drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps)(App));