import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import InfoView from 'components/InfoView';
import { userSignIn } from 'actions/Auth';
import { MainLogo, AppName } from 'constants/Environtments';
import LinearProgress from '@material-ui/core/LinearProgress';
import { verifyEmail } from 'util/ApiFetchers';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';

class Verify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            completed: 0,
            buffer: 10,
            token: null,
            success: false,
            message: '',
            onProgress: true,
            btnGoHome: false
        };
    };

    componentDidUpdate() {
        if (this.props.authUser !== null) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        this.timer = setInterval(this.progress, 500);
        let params = queryString.parse(this.props.location.search);
        let request = new FormData();
        request.append('token', params.token);
        verifyEmail(request).then(({ data }) => {
            this.setState({onProgress: false});
            if (data.success) {
                this.setState({
                    success: true,
                    message: data.message
                });
                this.props.history.push('/signin');
            }
        }).catch(error => {
            this.setState({onProgress: false, btnGoHome: true});
            if (error.response.data.code === '0391') {
                this.setState({
                    success: false,
                    message: error.response.data.errors['token']
                });
            } else {
                this.setState({
                    success: false,
                    message: error.response.data.errors[0] ? error.response.data.errors[0] : error.response.data.message
                });
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    progress = () => {
        const {completed} = this.state;
        if (completed > 100) {
            this.setState({completed: 0, buffer: 10});
        } else {
            const diff = Math.random() * 10;
            const diff2 = Math.random() * 10;
            this.setState({completed: completed + diff, buffer: completed + diff + diff2});
        }
    };

    render() {

        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title={AppName}>
                            <img src={MainLogo} height="110" alt={AppName} title={AppName} />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header">
                            <h1>Verifying Email Address Now ...</h1>
                        </div>
                        {
                            this.state.onProgress ?
                            <div>
                                <LinearProgress mode="buffer" value={this.state.completed} valueBuffer={this.state.buffer}/>
                                <br/>
                                <LinearProgress color="primary" mode="buffer" value={this.state.completed} valueBuffer={this.state.buffer}/>
                            </div>:<h2 className={this.state.success ? "text-success":"text-danger"} align="center">{this.state.message}</h2>
                        }
                        {
                            this.state.btnGoHome ?
                            <div align="center" className="mt-3">
                                <NavLink to="/signup">
                                    <Button variant="contained" color="primary" className="jr-btn jr-btn-lg">
                                        Try To Register Again
                                    </Button>
                                </NavLink>
                            </div> : ''
                        }
                    </div>
                </div>
                <InfoView />
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser }
};

export default connect(mapStateToProps, { userSignIn })(Verify);
