import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CustomScrollbars from "util/CustomScrollbars";
import { IN_REVIEW, APPROVED, CANCELED, REJECTED } from 'constants/SubmissionStatus';
import configureStore from "store";

const VisibleMenu = configureStore().getState().auth.visibleMenu;

class SidenavContent extends Component {
    componentDidMount() {
        const { history } = this.props;
        const that = this;
        const pathname = `${history.location.pathname}`;

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {

                const parentLiEle = that.closest(this, "li");
                if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
                    event.stopPropagation();

                    if (menuLi[i].classList.contains("open")) {
                        menuLi[i].classList.remove("open", "active");
                    } else {
                        menuLi[i].classList.add("open", "active");
                    }
                } else {
                    for (let j = 0; j < menuLi.length; j++) {
                        const parentLi = that.closest(this, "li");
                        if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains("open"))) {
                            menuLi[j].classList.remove("open");
                        } else {
                            if (menuLi[j].classList.contains("open")) {
                                menuLi[j].classList.remove("open");
                            } else {
                                menuLi[j].classList.add("open");
                            }
                        }
                    }
                }
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');
        try {
            const activeNav = this.closest(activeLi, 'ul');
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    componentWillReceiveProps(nextProps) {
        const { history } = nextProps;
        const pathname = `${history.location.pathname}`;

        const activeLi = document.querySelector('a[href="' + pathname + '"]');
        try {
            const activeNav = this.closest(activeLi, 'ul');
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] === "function") {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        return (
            <CustomScrollbars className=" scrollbar">
                <ul className="nav-menu">

                    <li className="nav-header">
                        <NavLink className="prepend-icon" to="/app/dashboard">
                            <span className="nav-text">Dashboard</span>
                        </NavLink>
                    </li>

                    {VisibleMenu.reference ?
                        <li className="menu collapse-box">
                            <Button>
                                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                                <span className="nav-text">
                                    Referensi
                                </span>
                            </Button>
                            <ul className="sub-menu">
                                {VisibleMenu.topicList ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/topics">
                                            <span className="nav-text">
                                                Topik
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.questionnaireList ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/questionnaires">
                                            <span className="nav-text">
                                                Kuesioner
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.bankAccount ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/banks">
                                            <span className="nav-text">
                                                Rekening Bank
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.periodMenu ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/periods">
                                            <span className="nav-text">
                                                Pengaturan Periode
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.emailTemplate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/email-templates">
                                            <span className="nav-text">
                                                Template Email
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }

                    {VisibleMenu.emailTemplate ?
                        <li className="menu collapse-box">
                            <Button>
                                <i className="zmdi zmdi-email-open zmdi-hc-fw" />
                                <span className="nav-text">
                                    Email Massal
                                </span>
                            </Button>
                            <ul className="sub-menu">
                                {VisibleMenu.emailTemplate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/email-templates">
                                            <span className="nav-text">
                                                Template Email
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.emailTemplate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/email-templates/logs">
                                            <span className="nav-text">
                                                Riwayat
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }

                    {VisibleMenu.user ?
                        <li className="menu collapse-box">
                            <Button>
                                <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                                <span className="nav-text">
                                    Pengguna
                                </span>
                            </Button>
                            <ul className="sub-menu">
                                {VisibleMenu.userCreate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/users/create">
                                            <span className="nav-text">
                                                <i className="zmdi zmdi-plus zmdi-hc-fw" />
                                                Tambah Pengguna Baru
                                            </span>
                                        </NavLink>
                                    </li> : ''
                                }
                                {VisibleMenu.userList ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/users">
                                            <span className="nav-text">
                                                <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                                                Daftar Pengguna
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }

                    {VisibleMenu.submission ?
                        <li className="menu collapse-box">
                            <Button>
                                <i className="zmdi zmdi-edit zmdi-hc-fw" />
                                <span className="nav-text">
                                    Submisi
                                </span>
                            </Button>
                            <ul className="sub-menu">
                                {VisibleMenu.abstractCreate ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/abstracts/create">
                                            <span className="nav-text">
                                                Ajukan Submisi
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status="}>
                                            <span className="nav-text">
                                                Daftar Semua Submisi
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status=" + IN_REVIEW}>
                                            <span className="nav-text">
                                                Dalam Peninjauan
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status=" + APPROVED}>
                                            <span className="nav-text">
                                                Disetujui
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status=" + CANCELED}>
                                            <span className="nav-text">
                                                Dibatalkan
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                                {VisibleMenu.abstractListAll ?
                                    <li>
                                        <NavLink className="prepend-icon" to={"/app/abstracts?&topic=&status=" + REJECTED}>
                                            <span className="nav-text">
                                                Ditolak
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }

                    {VisibleMenu.fullpaper ?
                        <li className="menu collapse-box">
                            <Button>
                                <i className="zmdi zmdi-collection-text zmdi-hc-fw" />
                                <span className="nav-text">
                                    FullPaper
                                </span>
                            </Button>
                            <ul className="sub-menu">
                                {VisibleMenu.confirmedSubmission ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/abstracts/confirmed">
                                            <span className="nav-text">
                                                Submisi Terkonfirmasi
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }

                    {VisibleMenu.proceedingView ?
                        <li className="menu collapse-box">
                            <Button>
                                <i className="zmdi zmdi-cloud-download zmdi-hc-fw" />
                                <span className="nav-text">
                                    Proceeding
                                </span>
                            </Button>
                            <ul className="sub-menu">
                                {VisibleMenu.proceedingView ?
                                    <li>
                                        <NavLink className="prepend-icon" to="/app/proceedings">
                                            <span className="nav-text">
                                                Unduh Proceeding
                                            </span>
                                        </NavLink>
                                    </li> : ''}
                            </ul>
                        </li> : ''
                    }
                </ul>
            </CustomScrollbars>
        );
    }
}

export default withRouter(SidenavContent);
