import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SIGNOUT_USER_SUCCESS,
    USER_DATA,
    USER_TOKEN_SET,
    USER_VISIBLE_MENU
} from "../constants/ActionTypes";
import {
    userRegister,
    getProfile,
    signOut
} from 'util/ApiFetchers';
import axios from 'util/Api';
import { VISIBLE_MENU } from 'constants/UserVisibleMenu';
import {
    SUPERADMIN,
    ADMIN,
    MEMBER,
    REVIEWER
} from 'constants/UserRole';

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const userSignUp = (request) => {
    return (dispatch, props) => {
        dispatch({ type: FETCH_START });
        userRegister(request).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS, payload: data.message });
                window.location.href = '/signin?&register=' + true;
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
};

export const userSignIn = (response) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        localStorage.setItem("token", JSON.stringify(response.credentials.access_token));
        axios.defaults.headers.common['access-token'] = "Bearer " + response.credentials.access_token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: response.credentials.access_token });
        dispatch({ type: USER_DATA, payload: response.profile });
    }
};

export const getUser = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        getProfile().then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: USER_DATA, payload: data.data.profile });
                localStorage.setItem("user", JSON.stringify(data.data.profile));
                const role = data.data.profile.role;

                if (role === SUPERADMIN) {
                    VISIBLE_MENU.reference = true;
                    VISIBLE_MENU.topic = true;
                    VISIBLE_MENU.topicList = true;
                    VISIBLE_MENU.topicCreate = true;
                    VISIBLE_MENU.topicEdit = true;
                    VISIBLE_MENU.topicDelete = true;
                    VISIBLE_MENU.user = true;
                    VISIBLE_MENU.userList = true;
                    VISIBLE_MENU.userCreate = true;
                    VISIBLE_MENU.userDetail = true;
                    VISIBLE_MENU.userEdit = true;
                    VISIBLE_MENU.userDelete = false;
                    VISIBLE_MENU.submission = true;
                    VISIBLE_MENU.abstractCreate = true;
                    VISIBLE_MENU.abstractListAll = true;
                    VISIBLE_MENU.abstractListMyOwn = true;
                    VISIBLE_MENU.abstractDetail = true;
                    VISIBLE_MENU.abstractEdit = false;
                    VISIBLE_MENU.abstractDelete = false;
                    VISIBLE_MENU.abstractReject = true;
                    VISIBLE_MENU.abstractApprove = true;
                    VISIBLE_MENU.abstractInReview = true;
                    VISIBLE_MENU.abstractSetPriority = true;
                    VISIBLE_MENU.abstractChangePreference = true;
                    VISIBLE_MENU.abstractChangeStatus = true;
                    VISIBLE_MENU.questionnaire = true;
                    VISIBLE_MENU.questionnaireList = true;
                    VISIBLE_MENU.questionnaireCreate = true;
                    VISIBLE_MENU.questionnaireEdit = true;
                    VISIBLE_MENU.questionnaireDelete = true;
                    VISIBLE_MENU.questionnaireDetail = true;
                    VISIBLE_MENU.abstractGiveReview = true;
                    VISIBLE_MENU.abstractShowReview = true;
                    VISIBLE_MENU.abstractDoConfirmation = true;
                    VISIBLE_MENU.fullpaper = true;
                    VISIBLE_MENU.confirmedSubmission = true;
                    VISIBLE_MENU.fullpaperCreate = true;
                    VISIBLE_MENU.bankAccount = true;
                    VISIBLE_MENU.bankAccountCreate = true;
                    VISIBLE_MENU.bankAccountUpdate = true;
                    VISIBLE_MENU.bankAccountDelete = true;
                    VISIBLE_MENU.bankAccountList = true;
                    VISIBLE_MENU.giveActionToPayment = true;
                    VISIBLE_MENU.periodMenu = true;
                    VISIBLE_MENU.emailTemplate = true;
                    VISIBLE_MENU.emailTemplateView = true;
                    VISIBLE_MENU.emailTemplateCreate = true;
                    VISIBLE_MENU.emailTemplateUpdate = true;
                    VISIBLE_MENU.emailTemplateDelete = true;
                    VISIBLE_MENU.updateMyProfile = true;
                    VISIBLE_MENU.changeMyPassword = true;
                    VISIBLE_MENU.doSendBlastApprovedMail = true;
                    VISIBLE_MENU.doSendBlastRejectedMail = true;
                    VISIBLE_MENU.doSendBlastCustomeMail = true;
                    VISIBLE_MENU.proceedingView = true;
                    VISIBLE_MENU.proceedingRequest = true;
                    VISIBLE_MENU.dashboardStatisticView = true;
                }

                if (role === ADMIN) {
                    VISIBLE_MENU.reference = true;
                    VISIBLE_MENU.topic = true;
                    VISIBLE_MENU.topicList = true;
                    VISIBLE_MENU.topicCreate = true;
                    VISIBLE_MENU.topicEdit = true;
                    VISIBLE_MENU.topicDelete = false;
                    VISIBLE_MENU.user = true;
                    VISIBLE_MENU.userList = true;
                    VISIBLE_MENU.userCreate = true;
                    VISIBLE_MENU.userDetail = true;
                    VISIBLE_MENU.userEdit = true;
                    VISIBLE_MENU.userDelete = false;
                    VISIBLE_MENU.submission = true;
                    VISIBLE_MENU.abstractCreate = true;
                    VISIBLE_MENU.abstractListAll = true;
                    VISIBLE_MENU.abstractListMyOwn = true;
                    VISIBLE_MENU.abstractDetail = true;
                    VISIBLE_MENU.abstractEdit = false;
                    VISIBLE_MENU.abstractDelete = false;
                    VISIBLE_MENU.abstractReject = true;
                    VISIBLE_MENU.abstractApprove = true;
                    VISIBLE_MENU.abstractInReview = true;
                    VISIBLE_MENU.abstractSetPriority = true;
                    VISIBLE_MENU.abstractChangePreference = true;
                    VISIBLE_MENU.abstractChangeStatus = true;
                    VISIBLE_MENU.questionnaire = true;
                    VISIBLE_MENU.questionnaireList = true;
                    VISIBLE_MENU.questionnaireCreate = true;
                    VISIBLE_MENU.questionnaireEdit = true;
                    VISIBLE_MENU.questionnaireDelete = false;
                    VISIBLE_MENU.questionnaireDetail = true;
                    VISIBLE_MENU.abstractGiveReview = true;
                    VISIBLE_MENU.abstractShowReview = true;
                    VISIBLE_MENU.abstractDoConfirmation = true;
                    VISIBLE_MENU.fullpaper = true;
                    VISIBLE_MENU.confirmedSubmission = true;
                    VISIBLE_MENU.fullpaperCreate = false;
                    VISIBLE_MENU.bankAccount = true;
                    VISIBLE_MENU.bankAccountCreate = true;
                    VISIBLE_MENU.bankAccountUpdate = true;
                    VISIBLE_MENU.bankAccountDelete = true;
                    VISIBLE_MENU.bankAccountList = true;
                    VISIBLE_MENU.giveActionToPayment = true;
                    VISIBLE_MENU.periodMenu = true;
                    VISIBLE_MENU.emailTemplate = true;
                    VISIBLE_MENU.emailTemplateView = true;
                    VISIBLE_MENU.emailTemplateCreate = true;
                    VISIBLE_MENU.emailTemplateUpdate = true;
                    VISIBLE_MENU.emailTemplateDelete = false;
                    VISIBLE_MENU.updateMyProfile = true;
                    VISIBLE_MENU.changeMyPassword = true;
                    VISIBLE_MENU.doSendBlastApprovedMail = true;
                    VISIBLE_MENU.doSendBlastRejectedMail = true;
                    VISIBLE_MENU.doSendBlastCustomeMail = true;
                    VISIBLE_MENU.proceedingView = true;
                    VISIBLE_MENU.proceedingRequest = true;
                    VISIBLE_MENU.dashboardStatisticView = true;
                }

                if (role === MEMBER) {
                    VISIBLE_MENU.reference = false;
                    VISIBLE_MENU.topic = false;
                    VISIBLE_MENU.topicList = false;
                    VISIBLE_MENU.topicCreate = false;
                    VISIBLE_MENU.topicEdit = false;
                    VISIBLE_MENU.topicDelete = false;
                    VISIBLE_MENU.user = false;
                    VISIBLE_MENU.userList = false;
                    VISIBLE_MENU.userCreate = false;
                    VISIBLE_MENU.userDetail = false;
                    VISIBLE_MENU.userEdit = false;
                    VISIBLE_MENU.userDelete = false;
                    VISIBLE_MENU.submission = true;
                    VISIBLE_MENU.abstractCreate = true;
                    VISIBLE_MENU.abstractListAll = true;
                    VISIBLE_MENU.abstractListMyOwn = true;
                    VISIBLE_MENU.abstractDetail = true;
                    VISIBLE_MENU.abstractEdit = false;
                    VISIBLE_MENU.abstractDelete = false;
                    VISIBLE_MENU.abstractReject = false;
                    VISIBLE_MENU.abstractApprove = false;
                    VISIBLE_MENU.abstractInReview = false;
                    VISIBLE_MENU.abstractSetPriority = false;
                    VISIBLE_MENU.abstractChangePreference = false;
                    VISIBLE_MENU.abstractChangeStatus = false;
                    VISIBLE_MENU.questionnaire = false;
                    VISIBLE_MENU.questionnaireList = false;
                    VISIBLE_MENU.questionnaireCreate = false;
                    VISIBLE_MENU.questionnaireEdit = false;
                    VISIBLE_MENU.questionnaireDelete = false;
                    VISIBLE_MENU.questionnaireDetail = false;
                    VISIBLE_MENU.abstractGiveReview = false;
                    VISIBLE_MENU.abstractShowReview = false;
                    VISIBLE_MENU.abstractDoConfirmation = true;
                    VISIBLE_MENU.fullpaper = true;
                    VISIBLE_MENU.confirmedSubmission = true;
                    VISIBLE_MENU.fullpaperCreate = true;
                    VISIBLE_MENU.bankAccount = false;
                    VISIBLE_MENU.bankAccountCreate = false;
                    VISIBLE_MENU.bankAccountUpdate = false;
                    VISIBLE_MENU.bankAccountDelete = false;
                    VISIBLE_MENU.bankAccountList = true;
                    VISIBLE_MENU.giveActionToPayment = false;
                    VISIBLE_MENU.periodMenu = false;
                    VISIBLE_MENU.emailTemplate = false;
                    VISIBLE_MENU.emailTemplateView = false;
                    VISIBLE_MENU.emailTemplateCreate = false;
                    VISIBLE_MENU.emailTemplateUpdate = false;
                    VISIBLE_MENU.emailTemplateDelete = false;
                    VISIBLE_MENU.updateMyProfile = true;
                    VISIBLE_MENU.changeMyPassword = true;
                    VISIBLE_MENU.doSendBlastApprovedMail = false;
                    VISIBLE_MENU.doSendBlastRejectedMail = false;
                    VISIBLE_MENU.doSendBlastCustomeMail = false;
                    VISIBLE_MENU.proceedingView = false;
                    VISIBLE_MENU.proceedingRequest = false;
                    VISIBLE_MENU.dashboardStatisticView = false;
                }

                if (role === REVIEWER) {
                    VISIBLE_MENU.reference = false;
                    VISIBLE_MENU.topic = false;
                    VISIBLE_MENU.topicList = false;
                    VISIBLE_MENU.topicCreate = false;
                    VISIBLE_MENU.topicEdit = false;
                    VISIBLE_MENU.topicDelete = false;
                    VISIBLE_MENU.user = false;
                    VISIBLE_MENU.userList = false;
                    VISIBLE_MENU.userCreate = false;
                    VISIBLE_MENU.userDetail = false;
                    VISIBLE_MENU.userEdit = false;
                    VISIBLE_MENU.userDelete = false;
                    VISIBLE_MENU.submission = true;
                    VISIBLE_MENU.abstractCreate = false;
                    VISIBLE_MENU.abstractListAll = true;
                    VISIBLE_MENU.abstractListMyOwn = false;
                    VISIBLE_MENU.abstractDetail = true;
                    VISIBLE_MENU.abstractEdit = false;
                    VISIBLE_MENU.abstractDelete = false;
                    VISIBLE_MENU.abstractReject = false;
                    VISIBLE_MENU.abstractApprove = false;
                    VISIBLE_MENU.abstractInReview = false;
                    VISIBLE_MENU.abstractSetPriority = false;
                    VISIBLE_MENU.abstractChangePreference = false;
                    VISIBLE_MENU.abstractChangeStatus = false;
                    VISIBLE_MENU.questionnaire = false;
                    VISIBLE_MENU.questionnaireList = false;
                    VISIBLE_MENU.questionnaireCreate = false;
                    VISIBLE_MENU.questionnaireEdit = false;
                    VISIBLE_MENU.questionnaireDelete = false;
                    VISIBLE_MENU.questionnaireDetail = false;
                    VISIBLE_MENU.abstractGiveReview = true;
                    VISIBLE_MENU.abstractShowReview = true;
                    VISIBLE_MENU.abstractDoConfirmation = false;
                    VISIBLE_MENU.fullpaper = false;
                    VISIBLE_MENU.confirmedSubmission = false;
                    VISIBLE_MENU.fullpaperCreate = false;
                    VISIBLE_MENU.bankAccount = false;
                    VISIBLE_MENU.bankAccountCreate = false;
                    VISIBLE_MENU.bankAccountUpdate = false;
                    VISIBLE_MENU.bankAccountDelete = false;
                    VISIBLE_MENU.bankAccountList = false;
                    VISIBLE_MENU.giveActionToPayment = false;
                    VISIBLE_MENU.periodMenu = false;
                    VISIBLE_MENU.emailTemplate = false;
                    VISIBLE_MENU.emailTemplateView = false;
                    VISIBLE_MENU.emailTemplateCreate = false;
                    VISIBLE_MENU.emailTemplateUpdate = false;
                    VISIBLE_MENU.emailTemplateDelete = false;
                    VISIBLE_MENU.updateMyProfile = true;
                    VISIBLE_MENU.changeMyPassword = true;
                    VISIBLE_MENU.doSendBlastApprovedMail = false;
                    VISIBLE_MENU.doSendBlastRejectedMail = false;
                    VISIBLE_MENU.doSendBlastCustomeMail = false;
                    VISIBLE_MENU.proceedingView = false;
                    VISIBLE_MENU.proceedingRequest = false;
                    VISIBLE_MENU.dashboardStatisticView = false;
                }

                window.location.reload();
                dispatch({ type: USER_VISIBLE_MENU, payload: VISIBLE_MENU });
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.errors });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
};


export const userSignOut = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        signOut().then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: SIGNOUT_USER_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.error });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
};

export const syncVisibleMenu = (role) => {
    return (dispatch) => {
        if (role === SUPERADMIN) {
            VISIBLE_MENU.reference = true;
            VISIBLE_MENU.topic = true;
            VISIBLE_MENU.topicList = true;
            VISIBLE_MENU.topicCreate = true;
            VISIBLE_MENU.topicEdit = true;
            VISIBLE_MENU.topicDelete = true;
            VISIBLE_MENU.user = true;
            VISIBLE_MENU.userList = true;
            VISIBLE_MENU.userCreate = true;
            VISIBLE_MENU.userDetail = true;
            VISIBLE_MENU.userEdit = true;
            VISIBLE_MENU.userDelete = false;
            VISIBLE_MENU.submission = true;
            VISIBLE_MENU.abstractCreate = true;
            VISIBLE_MENU.abstractListAll = true;
            VISIBLE_MENU.abstractListMyOwn = true;
            VISIBLE_MENU.abstractDetail = true;
            VISIBLE_MENU.abstractEdit = false;
            VISIBLE_MENU.abstractDelete = false;
            VISIBLE_MENU.abstractReject = true;
            VISIBLE_MENU.abstractApprove = true;
            VISIBLE_MENU.abstractInReview = true;
            VISIBLE_MENU.abstractSetPriority = true;
            VISIBLE_MENU.abstractChangePreference = true;
            VISIBLE_MENU.abstractChangeStatus = true;
            VISIBLE_MENU.questionnaire = true;
            VISIBLE_MENU.questionnaireList = true;
            VISIBLE_MENU.questionnaireCreate = true;
            VISIBLE_MENU.questionnaireEdit = true;
            VISIBLE_MENU.questionnaireDelete = true;
            VISIBLE_MENU.questionnaireDetail = true;
            VISIBLE_MENU.abstractGiveReview = true;
            VISIBLE_MENU.abstractShowReview = true;
            VISIBLE_MENU.abstractDoConfirmation = true;
            VISIBLE_MENU.fullpaper = true;
            VISIBLE_MENU.confirmedSubmission = true;
            VISIBLE_MENU.fullpaperCreate = true;
            VISIBLE_MENU.bankAccount = true;
            VISIBLE_MENU.bankAccountCreate = true;
            VISIBLE_MENU.bankAccountUpdate = true;
            VISIBLE_MENU.bankAccountDelete = true;
            VISIBLE_MENU.bankAccountList = true;
            VISIBLE_MENU.giveActionToPayment = true;
            VISIBLE_MENU.periodMenu = true;
            VISIBLE_MENU.emailTemplate = true;
            VISIBLE_MENU.emailTemplateView = true;
            VISIBLE_MENU.emailTemplateCreate = true;
            VISIBLE_MENU.emailTemplateUpdate = true;
            VISIBLE_MENU.emailTemplateDelete = true;
            VISIBLE_MENU.updateMyProfile = true;
            VISIBLE_MENU.changeMyPassword = true;
            VISIBLE_MENU.doSendBlastApprovedMail = true;
            VISIBLE_MENU.doSendBlastRejectedMail = true;
            VISIBLE_MENU.doSendBlastCustomeMail = true;
            VISIBLE_MENU.proceedingView = true;
            VISIBLE_MENU.proceedingRequest = true;
            VISIBLE_MENU.dashboardStatisticView = true;
        }

        if (role === ADMIN) {
            VISIBLE_MENU.reference = true;
            VISIBLE_MENU.topic = true;
            VISIBLE_MENU.topicList = true;
            VISIBLE_MENU.topicCreate = true;
            VISIBLE_MENU.topicEdit = true;
            VISIBLE_MENU.topicDelete = false;
            VISIBLE_MENU.user = true;
            VISIBLE_MENU.userList = true;
            VISIBLE_MENU.userCreate = true;
            VISIBLE_MENU.userDetail = true;
            VISIBLE_MENU.userEdit = true;
            VISIBLE_MENU.userDelete = false;
            VISIBLE_MENU.submission = true;
            VISIBLE_MENU.abstractCreate = true;
            VISIBLE_MENU.abstractListAll = true;
            VISIBLE_MENU.abstractListMyOwn = true;
            VISIBLE_MENU.abstractDetail = true;
            VISIBLE_MENU.abstractEdit = false;
            VISIBLE_MENU.abstractDelete = false;
            VISIBLE_MENU.abstractReject = true;
            VISIBLE_MENU.abstractApprove = true;
            VISIBLE_MENU.abstractInReview = true;
            VISIBLE_MENU.abstractSetPriority = true;
            VISIBLE_MENU.abstractChangePreference = true;
            VISIBLE_MENU.abstractChangeStatus = true;
            VISIBLE_MENU.questionnaire = true;
            VISIBLE_MENU.questionnaireList = true;
            VISIBLE_MENU.questionnaireCreate = true;
            VISIBLE_MENU.questionnaireEdit = true;
            VISIBLE_MENU.questionnaireDelete = false;
            VISIBLE_MENU.questionnaireDetail = true;
            VISIBLE_MENU.abstractGiveReview = true;
            VISIBLE_MENU.abstractShowReview = true;
            VISIBLE_MENU.abstractDoConfirmation = true;
            VISIBLE_MENU.fullpaper = true;
            VISIBLE_MENU.confirmedSubmission = true;
            VISIBLE_MENU.fullpaperCreate = false;
            VISIBLE_MENU.bankAccount = true;
            VISIBLE_MENU.bankAccountCreate = true;
            VISIBLE_MENU.bankAccountUpdate = true;
            VISIBLE_MENU.bankAccountDelete = true;
            VISIBLE_MENU.bankAccountList = true;
            VISIBLE_MENU.giveActionToPayment = true;
            VISIBLE_MENU.periodMenu = true;
            VISIBLE_MENU.emailTemplate = true;
            VISIBLE_MENU.emailTemplateView = true;
            VISIBLE_MENU.emailTemplateCreate = true;
            VISIBLE_MENU.emailTemplateUpdate = true;
            VISIBLE_MENU.emailTemplateDelete = false;
            VISIBLE_MENU.updateMyProfile = true;
            VISIBLE_MENU.changeMyPassword = true;
            VISIBLE_MENU.doSendBlastApprovedMail = true;
            VISIBLE_MENU.doSendBlastRejectedMail = true;
            VISIBLE_MENU.doSendBlastCustomeMail = true;
            VISIBLE_MENU.proceedingView = true;
            VISIBLE_MENU.proceedingRequest = true;
            VISIBLE_MENU.dashboardStatisticView = true;
        }

        if (role === MEMBER) {
            VISIBLE_MENU.reference = false;
            VISIBLE_MENU.topic = false;
            VISIBLE_MENU.topicList = false;
            VISIBLE_MENU.topicCreate = false;
            VISIBLE_MENU.topicEdit = false;
            VISIBLE_MENU.topicDelete = false;
            VISIBLE_MENU.user = false;
            VISIBLE_MENU.userList = false;
            VISIBLE_MENU.userCreate = false;
            VISIBLE_MENU.userDetail = false;
            VISIBLE_MENU.userEdit = false;
            VISIBLE_MENU.userDelete = false;
            VISIBLE_MENU.submission = true;
            VISIBLE_MENU.abstractCreate = true;
            VISIBLE_MENU.abstractListAll = true;
            VISIBLE_MENU.abstractListMyOwn = true;
            VISIBLE_MENU.abstractDetail = true;
            VISIBLE_MENU.abstractEdit = false;
            VISIBLE_MENU.abstractDelete = false;
            VISIBLE_MENU.abstractReject = false;
            VISIBLE_MENU.abstractApprove = false;
            VISIBLE_MENU.abstractInReview = false;
            VISIBLE_MENU.abstractSetPriority = false;
            VISIBLE_MENU.abstractChangePreference = false;
            VISIBLE_MENU.abstractChangeStatus = false;
            VISIBLE_MENU.questionnaire = false;
            VISIBLE_MENU.questionnaireList = false;
            VISIBLE_MENU.questionnaireCreate = false;
            VISIBLE_MENU.questionnaireEdit = false;
            VISIBLE_MENU.questionnaireDelete = false;
            VISIBLE_MENU.questionnaireDetail = false;
            VISIBLE_MENU.abstractGiveReview = false;
            VISIBLE_MENU.abstractShowReview = false;
            VISIBLE_MENU.abstractDoConfirmation = true;
            VISIBLE_MENU.fullpaper = true;
            VISIBLE_MENU.confirmedSubmission = true;
            VISIBLE_MENU.fullpaperCreate = true;
            VISIBLE_MENU.bankAccount = false;
            VISIBLE_MENU.bankAccountCreate = false;
            VISIBLE_MENU.bankAccountUpdate = false;
            VISIBLE_MENU.bankAccountDelete = false;
            VISIBLE_MENU.bankAccountList = true;
            VISIBLE_MENU.giveActionToPayment = false;
            VISIBLE_MENU.periodMenu = false;
            VISIBLE_MENU.emailTemplate = false;
            VISIBLE_MENU.emailTemplateView = false;
            VISIBLE_MENU.emailTemplateCreate = false;
            VISIBLE_MENU.emailTemplateUpdate = false;
            VISIBLE_MENU.emailTemplateDelete = false;
            VISIBLE_MENU.updateMyProfile = true;
            VISIBLE_MENU.changeMyPassword = true;
            VISIBLE_MENU.doSendBlastApprovedMail = false;
            VISIBLE_MENU.doSendBlastRejectedMail = false;
            VISIBLE_MENU.doSendBlastCustomeMail = false;
            VISIBLE_MENU.proceedingView = false;
            VISIBLE_MENU.proceedingRequest = false;
            VISIBLE_MENU.dashboardStatisticView = false;
        }

        if (role === REVIEWER) {
            VISIBLE_MENU.reference = false;
            VISIBLE_MENU.topic = false;
            VISIBLE_MENU.topicList = false;
            VISIBLE_MENU.topicCreate = false;
            VISIBLE_MENU.topicEdit = false;
            VISIBLE_MENU.topicDelete = false;
            VISIBLE_MENU.user = false;
            VISIBLE_MENU.userList = false;
            VISIBLE_MENU.userCreate = false;
            VISIBLE_MENU.userDetail = false;
            VISIBLE_MENU.userEdit = false;
            VISIBLE_MENU.userDelete = false;
            VISIBLE_MENU.submission = true;
            VISIBLE_MENU.abstractCreate = false;
            VISIBLE_MENU.abstractListAll = true;
            VISIBLE_MENU.abstractListMyOwn = false;
            VISIBLE_MENU.abstractDetail = true;
            VISIBLE_MENU.abstractEdit = false;
            VISIBLE_MENU.abstractDelete = false;
            VISIBLE_MENU.abstractReject = false;
            VISIBLE_MENU.abstractApprove = false;
            VISIBLE_MENU.abstractInReview = false;
            VISIBLE_MENU.abstractSetPriority = false;
            VISIBLE_MENU.abstractChangePreference = false;
            VISIBLE_MENU.abstractChangeStatus = false;
            VISIBLE_MENU.questionnaire = false;
            VISIBLE_MENU.questionnaireList = false;
            VISIBLE_MENU.questionnaireCreate = false;
            VISIBLE_MENU.questionnaireEdit = false;
            VISIBLE_MENU.questionnaireDelete = false;
            VISIBLE_MENU.questionnaireDetail = false;
            VISIBLE_MENU.abstractGiveReview = true;
            VISIBLE_MENU.abstractShowReview = true;
            VISIBLE_MENU.abstractDoConfirmation = false;
            VISIBLE_MENU.fullpaper = false;
            VISIBLE_MENU.confirmedSubmission = false;
            VISIBLE_MENU.fullpaperCreate = false;
            VISIBLE_MENU.bankAccount = false;
            VISIBLE_MENU.bankAccountCreate = false;
            VISIBLE_MENU.bankAccountUpdate = false;
            VISIBLE_MENU.bankAccountDelete = false;
            VISIBLE_MENU.bankAccountList = false;
            VISIBLE_MENU.giveActionToPayment = false;
            VISIBLE_MENU.periodMenu = false;
            VISIBLE_MENU.emailTemplate = false;
            VISIBLE_MENU.emailTemplateView = false;
            VISIBLE_MENU.emailTemplateCreate = false;
            VISIBLE_MENU.emailTemplateUpdate = false;
            VISIBLE_MENU.emailTemplateDelete = false;
            VISIBLE_MENU.updateMyProfile = true;
            VISIBLE_MENU.changeMyPassword = true;
            VISIBLE_MENU.doSendBlastApprovedMail = false;
            VISIBLE_MENU.doSendBlastRejectedMail = false;
            VISIBLE_MENU.doSendBlastCustomeMail = false;
            VISIBLE_MENU.proceedingView = false;
            VISIBLE_MENU.proceedingRequest = false;
            VISIBLE_MENU.dashboardStatisticView = false;
        }

        dispatch({ type: USER_VISIBLE_MENU, payload: VISIBLE_MENU });
    };
}