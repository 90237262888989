import React from 'react';

const Footer = () => {
    return (
        <footer className="app-footer">
            <span className="d-inline-block">
                Copyright &copy; <a target="_blank" rel="noopener noreferrer" href="https://fajransyah.id?&from=submission"><strong>FAJRIANSYAH.ID</strong></a> 2019
            </span>
        </footer>
    );
};

export default Footer;
