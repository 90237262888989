import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { userSignUp } from '../actions/Auth';
import { MainLogo, AppName } from 'constants/Environtments';
import { FormHelperText } from '@material-ui/core';

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValid: false,
            name: '',
            nameError: false,
            nameErrorMsg: '',
            email: '',
            emailError: false,
            emailErrorMsg: '',
            phone: '',
            phoneError: false,
            phoneErrorMsg: '',
            address: '',
            addressError: false,
            addressErrorMsg: '',
            institution: '',
            institutionError: false,
            institutionErrorMsg: '',
            password: '',
            passwordError: false,
            passwordErrorMsg: '',
            passwordConfirmation: '',
            passwordConfirmationError: false,
            passwordConfirmationErrorMsg: ''
        }
    }

    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 3000);
        }
        if (this.props.authUser !== null) {
            this.props.history.push('/');
        }
    }

    handleClickRegister = () => {
        let request = new FormData();
        request.append('name', this.state.name);
        request.append('email', this.state.email);
        request.append('phone', this.state.phone);
        request.append('address', this.state.address);
        request.append('institution', this.state.institution);
        request.append('password', this.state.password);
        request.append('password_confirmation', this.state.passwordConfirmation);

        let errors = [];
        for (var pair of request.entries()) {
            if (pair[1] === '') {
                this.validation(pair[0]);
                errors.push(pair[0]);
            }
        }

        if (errors.length === 0) {
            this.props.userSignUp(request)
        }
    }

    validation = (field) => {
        if (field === 'name') {
            this.setState({
                nameError: true,
                nameErrorMsg: 'Field name is required.'
            });
        }

        if (field === 'email') {
            this.setState({
                emailError: true,
                emailErrorMsg: 'Field email is required.'
            });
        }

        if (field === 'phone') {
            this.setState({
                phoneError: true,
                phoneErrorMsg: 'Field phone is required.'
            });
        }

        if (field === 'address') {
            this.setState({
                addressError: true,
                addressErrorMsg: 'Field address is required.'
            });
        }

        if (field === 'institution') {
            this.setState({
                institutionError: true,
                institutionErrorMsg: 'Field institution is required.'
            });
        }

        if (field === 'password') {
            this.setState({
                passwordError: true,
                passwordErrorMsg: 'Field password is required.'
            });
        }

        if (field === 'password_confirmation') {
            this.setState({
                passwordConfirmationError: true,
                passwordConfirmationErrorMsg: 'Field passwordConfirmation is required.'
            });
        }
    }

    render() {
        return (
            <div className="app-wrapper" style={{ overflowY: 'auto' }}>
                <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-5">
                    <div className="app-login-main-content">
                        <div className="app-logo-content d-flex align-items-center justify-content-center bg-primary">
                            <Link className="logo-lg" to="/" title={AppName}>
                                <img src={MainLogo} height="110" alt={AppName} title={AppName} />
                            </Link>
                        </div>

                        <div className="app-login-content">
                            <div className="app-login-header">
                                <h1>Daftar</h1>
                            </div>

                            <div className="mb-4">
                                <h2>Buat akun {AppName} baru</h2>
                            </div>

                            <div className="app-login-form">
                                <form method="post" action="/">
                                    <TextField
                                        type="text"
                                        label="Nama Lengkap"
                                        variant="outlined"
                                        required
                                        error={this.state.nameError}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                        fullWidth
                                        defaultValue={this.state.name}
                                        margin="normal"
                                        className={this.state.nameError ? 'mt-0 mb-0' : 'mt-0 mb-3'}
                                    />
                                    {this.state.nameError ?
                                        <FormHelperText className="text-danger mb-3">{this.state.nameErrorMsg}</FormHelperText> : ''}


                                    <TextField
                                        type="email"
                                        variant="outlined"
                                        required
                                        error={this.state.emailError}
                                        onChange={(event) => this.setState({ email: event.target.value })}
                                        label={<IntlMessages id="appModule.email" />}
                                        fullWidth
                                        defaultValue={this.state.email}
                                        margin="normal"
                                        className={this.state.nameError ? 'mt-0 mb-0' : 'mt-0 mb-3'}
                                    />
                                    {this.state.emailError ?
                                        <FormHelperText className="text-danger mb-3">{this.state.emailErrorMsg}</FormHelperText> : ''}

                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        required
                                        error={this.state.phoneError}
                                        onChange={(event) => this.setState({ phone: event.target.value })}
                                        label="Nomor Ponsel"
                                        fullWidth
                                        defaultValue={this.state.phone}
                                        margin="normal"
                                        className={this.state.nameError ? 'mt-0 mb-0' : 'mt-0 mb-3'}
                                    />
                                    {this.state.phoneError ?
                                        <FormHelperText className="text-danger mb-3">{this.state.phoneErrorMsg}</FormHelperText> : ''}

                                    <TextField
                                        type="text"
                                        multiline
                                        rows="3"
                                        variant="outlined"
                                        required
                                        error={this.state.addressError}
                                        onChange={(event) => this.setState({ address: event.target.value })}
                                        label="Alamat"
                                        fullWidth
                                        defaultValue={this.state.address}
                                        margin="normal"
                                        className={this.state.nameError ? 'mt-0 mb-0' : 'mt-0 mb-3'}
                                    />
                                    {this.state.addressError ?
                                        <FormHelperText className="text-danger mb-3">{this.state.addressErrorMsg}</FormHelperText> : ''}

                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        required
                                        error={this.state.institutionError}
                                        onChange={(event) => this.setState({ institution: event.target.value })}
                                        label="Institusi"
                                        fullWidth
                                        defaultValue={this.state.institution}
                                        margin="normal"
                                        className={this.state.nameError ? 'mt-0 mb-0' : 'mt-0 mb-3'}
                                    />
                                    {this.state.institutionError ?
                                        <FormHelperText className="text-danger mb-3">{this.state.institutionErrorMsg}</FormHelperText> : ''}

                                    <TextField
                                        type="password"
                                        variant="outlined"
                                        required
                                        error={this.state.passwordError}
                                        onChange={(event) => this.setState({ password: event.target.value })}
                                        label="Kata Sandi"
                                        fullWidth
                                        defaultValue={this.state.password}
                                        margin="normal"
                                        className={this.state.nameError ? 'mt-0 mb-0' : 'mt-0 mb-3'}
                                    />
                                    {this.state.passwordError ?
                                        <FormHelperText className="text-danger mb-3">{this.state.passwordErrorMsg}</FormHelperText> : ''}

                                    <TextField
                                        type="password"
                                        variant="outlined"
                                        required
                                        error={this.state.passwordConfirmationError}
                                        onChange={(event) => this.setState({ passwordConfirmation: event.target.value })}
                                        label="Konfirmasi Kata Sandi"
                                        fullWidth
                                        defaultValue={this.state.passwordConfirmation}
                                        margin="normal"
                                        className={this.state.nameError ? 'mt-0 mb-0' : 'mt-0 mb-3'}
                                    />
                                    {this.state.passwordConfirmationError ?
                                        <FormHelperText className="text-danger mb-3">{this.state.passwordConfirmationErrorMsg}</FormHelperText> : ''}

                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button variant="contained" color="primary" onClick={this.handleClickRegister}>
                                            Daftar
                                        </Button>
                                        <Link to="/signin">
                                            Sudah memiliki akun ?
                                        </Link>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser }
};

export default connect(mapStateToProps, { userSignUp })(SignUp);
