import { FETCH_START, FETCH_ERROR, PERIOD_CONFIG } from "../constants/ActionTypes";
import { getPeriodList } from 'util/ApiFetchers';

export const syncPeriod = () => {
    return (dispatch) => {
        getPeriodList().then(({data}) => {
            if (data.success) {
                let periods = [];
                data.data.periods.forEach(period => {
                    periods.push({key: period.key, status: period.status});
                });
                dispatch({ type: FETCH_START });
                dispatch({ type: PERIOD_CONFIG, payload: periods });
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.errors });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
};
