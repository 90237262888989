export const VISIBLE_MENU = {
    reference: false,

    topic: false,
    topicList: false,
    topicCreate: false,
    topicEdit: false,
    topicDelete: false,

    user: false,
    userList: false,
    userCreate: false,
    userDetail: false,
    userEdit: false,
    userDelete: false,

    submission: false,
    abstractCreate: false,
    abstractListAll: false,
    abstractListMyOwn: true,
    abstractDetail: true,
    abstractEdit: false,
    abstractDelete: false,
    abstractReject: false,
    abstractApprove: false,
    abstractInReview: false,
    abstractSetPriority: false,
    abstractChangePreference: false,
    abstractChangeStatus: false,
    abstractGiveReview: false,
    abstractShowReview: false,
    abstractDoConfirmation: false,

    fullpaper: false,
    confirmedSubmission: false,
    fullpaperCreate: false,

    questionnaire: false,
    questionnaireList: false,
    questionnaireCreate: false,
    questionnaireEdit: false,
    questionnaireDelete: false,
    questionnaireDetail: false,

    bankAccount: false,
    bankAccountCreate: false,
    bankAccountUpdate: false,
    bankAccountDelete: false,
    bankAccountList: false,
    giveActionToPayment: false,
    periodMenu: false,

    emailTemplate: false,
    emailTemplateView: false,
    emailTemplateCreate: false,
    emailTemplateUpdate: false,
    emailTemplateDelete: false,

    updateMyProfile: false,
    changeMyPassword: false,

    doSendBlastApprovedMail: false,
    doSendBlastRejectedMail: false,
    doSendBlastCustomeMail: false,

    proceedingView: false,
    proceedingRequest: false,

    dashboardStatisticView: false,
};