import { PERIOD_CONFIG } from "../constants/ActionTypes";
import {
    CALL_FOR_ABSTRACT,
    REVIEW_PERIOD,
    FULLPAPER_PERIOD
} from '../constants/PeriodKey';
import {
    IS_OFF
} from '../constants/PeriodStatus';

const INIT_STATE = {
    fetched: false,
    configs: [
        {key: CALL_FOR_ABSTRACT, status: IS_OFF},
        {key: REVIEW_PERIOD, status: IS_OFF},
        {key: FULLPAPER_PERIOD, status: IS_OFF},
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PERIOD_CONFIG: {
            state.fetched = true;
            state.configs = action.payload;
            return state;
        }

        default:
            return state;
    }
};
