import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import { userSignOut } from 'actions/Auth';
import configureStore from "store";

const User = configureStore().getState().auth.authUser;

class UserInfo extends React.Component {

    state = {
        anchorEl: null,
        open: false,
    };

    handleClick = event => {
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };
    handleClickProfile = () => {
        this.props.history.push('/app/profiles');
    }
    handleClickChangePassword = () => {
        this.props.history.push('/app/change-passwords');
    }

    render() {
        console.log(User);
        return (
            <div className="user-profile d-flex flex-row align-items-center">
                <Avatar
                    alt={User.name}
                    src={User.avatar === null ? 'https://via.placeholder.com/150x150':User.avatar}
                    className="user-avatar "
                />
                <div className="user-detail">
                    <h4 className="user-name">{User.name}</h4>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { locale } = settings;
    return { locale }
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);


