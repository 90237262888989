import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import {APPROVED} from 'constants/SubmissionStatus';
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import Menu from 'components/TopNav/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import { MainLogo, AppName } from 'constants/Environtments';
import configureStore from "store";

export const GlobalState = configureStore().getState();

class Header extends React.Component {

    onAppNotificationSelect = () => {
        this.setState({
            appNotification: !this.state.appNotification
        })
    };
    onMailNotificationSelect = () => {
        this.setState({
            mailNotification: !this.state.mailNotification
        })
    };
    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
        })
    };
    onSearchBoxSelect = () => {
        this.setState({
            searchBox: !this.state.searchBox
        })
    };
    onAppsSelect = () => {
        this.setState({
            apps: !this.state.apps
        })
    };
    onUserInfoSelect = () => {
        this.setState({
            userInfo: !this.state.userInfo
        })
    };
    handleRequestClose = () => {
        this.setState({
            langSwitcher: false,
            userInfo: false,
            mailNotification: false,
            appNotification: false,
            searchBox: false,
            apps: false
        });
    };
    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };
    Apps = () => {
        return (
            <ul className="jr-list jr-list-half">
                <li className="jr-list-item">
                    <Link className="jr-list-link" to={`/app/abstracts/create`}>
                        <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw" />
                        <span className="jr-list-text text-center">Submisi</span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to={`/app/abstracts?&topic=&status=${APPROVED}`}>
                        <i className="zmdi zmdi-check-square zmdi-hc-fw" />
                        <span className="jr-list-text">Lolos</span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to={`/app/abstracts/confirmed`}>
                        <i className="zmdi zmdi-collection-text zmdi-hc-fw" />
                        <span className="jr-list-text">FullPaper</span>
                    </Link>
                </li>
            </ul>)
    };

    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            searchBox: false,
            searchText: '',
            mailNotification: false,
            userInfo: false,
            langSwitcher: false,
            appNotification: false,
        }
    }

    updateSearchText(evt) {
        this.setState({
            searchText: evt.target.value,
        });
    }

    render() {
        const user = GlobalState.auth.authUser;
        const { drawerType, navigationStyle, horizontalNavPosition } = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

        return (
            <AppBar
                className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
                <Toolbar className="app-toolbar" disableGutters={false}>
                    {navigationStyle === HORIZONTAL_NAVIGATION ?
                        <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                            <span className="jr-menu-icon">
                                <span className="menu-icon" />
                            </span>
                        </div>
                        :
                        <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                            onClick={this.onToggleCollapsedNav}>
                            <span className="menu-icon" />
                        </IconButton>
                    }

                    <Link className="app-logo mr-2 d-none d-sm-block" to="/app/dashboard">
                        <img src={MainLogo} alt={AppName} title={AppName} />
                    </Link>

                    {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
                        <Menu />}

                    <ul className="header-notifications list-inline ml-auto">
                        <li className="list-inline-item">
                            <Dropdown
                                className="quick-menu app-notification"
                                isOpen={this.state.apps}
                                toggle={this.onAppsSelect.bind(this)}>

                                <DropdownToggle
                                    className="d-inline-block"
                                    tag="span"
                                    data-toggle="dropdown">
                                    <span className="app-notification-menu">
                                        <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
                                        <span>Pintasan</span>
                                    </span>
                                </DropdownToggle>

                                <DropdownMenu>
                                    {this.Apps()}
                                </DropdownMenu>
                            </Dropdown>
                        </li>

                        {navigationStyle === HORIZONTAL_NAVIGATION &&
                            <li className="list-inline-item user-nav">
                                <Dropdown
                                    className="quick-menu"
                                    isOpen={this.state.userInfo}
                                    toggle={this.onUserInfoSelect.bind(this)}>

                                    <DropdownToggle
                                        className="d-inline-block"
                                        tag="span"
                                        data-toggle="dropdown">
                                            <Avatar className="size-40" alt={user.name} src={user.avatar} />
                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        <UserInfoPopup />
                                    </DropdownMenu>
                                </Dropdown>
                            </li>}
                    </ul>

                    <div className="ellipse-shape"></div>
                </Toolbar>
            </AppBar>
        );
    }

}


const mapStateToProps = ({ settings }) => {
    const { drawerType, navigationStyle, horizontalNavPosition } = settings;
    return { drawerType, navigationStyle, horizontalNavPosition }
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav, switchLanguage })(Header));